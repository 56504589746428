import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "draft": true
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`How I rate the restaurants`}</h3>
    <h4>{`Food, service and location are the main factors I consider, while the price influences my expectations`}</h4>
    <p>{`The Restaurants are in order of my preference.`}<br parentName="p"></br>{`
`}{`I haven't tried every restaurant in the list, but if I have, there is a brief
description of my experience and an overall Rating.`}</p>
    <p>{`The votes are, obviously, coherent with the price I paid:
I don't expect to have a Michelin-Starred dinner paying 30 euros.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      