import { ComponentStyleConfig } from '@chakra-ui/react';

const CustomHeadingStyle: ComponentStyleConfig = {
  baseStyle: {
    // letterSpacing: '-0.03em',
    fontWeight: '700',
    lineHeight: 1.65,
    color: 'heading',
  },
  variants: {
    h1: {
      fontSize: '2.5rem', // 1.75rem - 3xl
      // lineHeight: '1.1',
    },
    h2: {
      fontSize: '1.5rem', // 1.5rem - 2xl
      // lineHeight: '1.3',
    },
    h3: {
      fontSize: '1.25rem', // 1.25 rem - xl
      // lineHeight: '1.5',
    },
    h4: {
      fontSize: '1rem', // 1.25rem - xl
      // lineHeight: '125%',
    },
    labelHeading: {
      fontSize: '0.875rem',
      letterSpacing: '0.07rem',
      fontWeight: '600',
      fontFamily: 'body',
    },
    'h1-article': {
      fontSize: '2.5rem', // 1.75rem - 3xl
      bgGradient:
        'linear-gradient(180deg,rgba(255,255,255,0) 65%, rgb(172, 140, 100, 0.8) 65%)',
      width: 'fit-content',
      display: 'inline',
      marginX: 'auto',
    },
  },
};

export default CustomHeadingStyle;
