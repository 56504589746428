// src/@chakra-ui/gatsby-plugin/theme.js
import { extendTheme } from '@chakra-ui/react';
import {
  CustomButtonStyle,
  CustomHeadingStyle,
  CustomLinkStyle,
  CustomTextStyle,
  ResponsiveGridComponent,
  ResponsiveFlexComponent,
} from '../custom-components';

// Prev Palette
// heading: '#17252A',
//     body: '#2B7A78',
//     accent: '#3AAFA9',
//     bg: {
//       dark: '#DEF2F1',
//       light: '#FEFFFF',
//     },

const theme = {
  colors: {
    heading: '#282525',
    body: '#443e37', // prev: rgba(40, 37, 37, 0.7)
    accent: 'rgb(172, 140, 100)',
    bg: {
      200: 'rgba(40, 37, 37, 0.1)', // BG for line dividers
      100: 'rgb(251, 251, 251)', // Background for Boxes
    },
  },
  components: {
    Button: CustomButtonStyle,
    Text: CustomTextStyle,
    Heading: CustomHeadingStyle,
    Link: CustomLinkStyle,
    ResponsiveGrid: ResponsiveGridComponent,
    ResponsiveFlex: ResponsiveFlexComponent,
  },
  fonts: {
    heading: `ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji"`, // 'utopia-std-display',
    body: `ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji"`, // 'rubik'
  },
  fontSizes: {
    '3xl': '1.75rem',
  },
};

export default extendTheme(theme);
